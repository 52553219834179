import { EVALUATION_STATUS } from 'sharedKernel/constants'
import { hasTextMatch } from 'sharedKernel/handlers'
import { EVALUATORS_SHOWN_LIST } from '.'
import { ISolution } from '../contracts'

export const sortRows = (solutionsRows: any[], finalScoreAsc: boolean, solutionTitleAsc: boolean): any[] => {
  if (finalScoreAsc) {
    solutionsRows.sort((a, b) => a.evaluation.averageScore - b.evaluation.averageScore)
  } else {
    solutionsRows.sort((a, b) => b.evaluation.averageScore - a.evaluation.averageScore)
  }

  if (solutionTitleAsc) {
    solutionsRows.sort(() => 1)
  } else {
    solutionsRows.sort(() => -1)
  }

  return solutionsRows
}

export const filterByTabValue = (solution: ISolution, tabStatus: string): boolean => {
  if (tabStatus === '') {
    return true
  }

  return solution.status.name === tabStatus
}

export const filterValueMatchAnyField = (solution: ISolution, filterValue: string, columnMasks: any): boolean => {
  if (filterValue === '') {
    return true
  }

  if (hasTextMatch(solution.title, filterValue)) {
    return true
  }

  if (hasTextMatch(solution.evaluation.averageScore, filterValue)) {
    return true
  }

  if (hasTextMatch(solution.evaluation.standardDeviation, filterValue)) {
    return true
  }

  if (checkMatchEvaluationPoints(solution, filterValue, columnMasks)) {
    return true
  }

  return false
}

const checkMatchEvaluationPoints = (solution: ISolution, filterValue: string, columnMasks: any): boolean => {
  for (let index = 0; index < EVALUATORS_SHOWN_LIST; index++) {
    if (index >= solution.evaluation.evaluations.length) {
      break
    }

    if (solution.evaluation.evaluations[index].status === EVALUATION_STATUS.PENDING) {
      if (hasTextMatch(columnMasks.pendingEvaluation, filterValue)) return true
    }

    if (solution.evaluation.evaluations[index].status === EVALUATION_STATUS.NOT_ACCEPTED) {
      if (hasTextMatch(columnMasks.notAcceptedEvaluation, filterValue)) return true
    }

    if (hasTextMatch(solution.evaluation.evaluations[index].points, filterValue)) {
      return true
    }
  }

  return false
}
