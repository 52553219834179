export const sortRows = (solutionsRows: any[], evaluatorNameAsc: boolean): any[] => {
  if (evaluatorNameAsc) {
    solutionsRows.sort(() => 1)
  } else {
    solutionsRows.sort(() => -1)
  }
  return solutionsRows
}

export const sortResolverSolutions = (solutions: any[]): any[] => {
  solutions.sort((a, b) => {
    const x = a.title.toLowerCase()
    const y = b.title.toLowerCase()
    if (x < y) {
      return -1
    } else {
      return 1
    }
  })
  return solutions
}

export const sortModalSolutions = (solutions: any[]): any[] => {
  solutions.sort((a, b) => {
    const x = a.title.toLowerCase()
    const y = b.title.toLowerCase()
    if (x < y) {
      return -1
    } else {
      return 1
    }
  })
  return solutions
}
